/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
import './UtilityDetails.scss';

import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import style from '../style.module.scss';

const UtilityTitle = ({ paidBy = '', title }) => {
  const { t } = useTranslation('form');

  const handlePaidBy = (payee: string = '') => (payee === '0' ? 'Frank Porter' : 'Owner');

  return (
    <h3 style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <span style={{ fontSize: '1.2rem', color: '#AD8C63' }}>{t(title)}</span>
      {paidBy && (
        <span style={{ fontSize: '12px', color: '#23282cdd' }}>{handlePaidBy(paidBy)}</span>
      )}
    </h3>
  );
};

export default UtilityTitle;

const arrOfMappingUtilities = [
  { key: 'expenseName', title: 'Utility Name' },
  { key: 'account_number', title: 'Account No.' },
  { key: 'web_login', title: 'Web Login' },
  { key: 'web_pass', title: 'Web Password' },
  { key: 'bill_link', title: 'Bill Link' },
];

export const UtilityData = ({ type, summary, other = false }) => {
  if (!summary) return null;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      {arrOfMappingUtilities.map(({ key, title }) => {
        const value = summary?.[type]?.[key];

        if (['web_login', 'web_pass'].includes(key) && !value) {
          return <Fragment key={key} />;
        }

        if (!other && key === 'bill_link') {
          return <Fragment key={key} />;
        }

        return (
          <Fragment key={key}>
            {summary?.[key] || summary?.[type]?.[key] ? (
              <>
                <li
                  className="utlity-detail-title"
                  style={{ listStyle: 'none', fontSize: '16px', color: '#23282cdd' }}
                >
                  {title}
                </li>
                <p style={{ margin: 0, fontSize: '14px', color: '#ad8c63dd', fontWeight: 'bold' }}>
                  {other ? summary?.[key] || '-' : summary?.[type]?.[key] || '-'}
                </p>
              </>
            ) : (
              <></>
            )}
          </Fragment>
        );
      })}
    </div>
  );
};

export const UtilityCard = ({ summary, name }) => {
  if (!summary || !summary?.[name]) return <></>;

  return (
    <div className={style.col}>
      <UtilityTitle title={name} paidBy={summary?.[name]?.paid_by} />
      <UtilityData type={name} summary={summary} />
    </div>
  );
};
