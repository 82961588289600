import Axios from 'axios';
import {
  ADMIN_ACCESS_TOKEN_NAME,
  ADMIN_TOKEN_NAME,
  PORDAL_TOKEN_CONFIG,
  USER_SESSION,
  USER_STATUS,
  USER_TOKEN_NAME,
} from 'helpers/const';
import environment from '../environments/environment';

export default class AuthService {
  constructor() {
    this.currentUser = this.getUser();
    this.handleRefreshToken();
  }

  async login(email, password) {
    const postData = this.prepareLoginData(email, password);
    try {
      const result = await this.authenticatePost(postData);

      if (result.data.status) {
        localStorage.setItem(USER_STATUS, result.data.status);
        if (result.data.status === 'guesty') {
          this.setUserToken(result.data.token);
          this.getUserData();
        } else if (result.data.status === 'both') {
          this.setUserToken(result.data?.guesty?.token);
          this.setPordalConfig(result.data?.pordal);
          this.getUserData();
        } else if (result.data.status === 'pordal') {
          this.setUserToken(result.data?.access_token);
          this.setPordalConfig(result.data);

          await this.getPordalOwnerDetails();
        }

        return result.data.access_token;
      }
    } catch (error) {
      throw new Error('Username does not exist or password is incorrect');
    }
  }

  async getPordalOwnerDetails() {
    const result = await Axios.get(`${environment.pordalApi}/owner-profile`);
    if (result.data) {
      this.setUser({
        ...result.data,
        userId: result.data.id,
        name: result.data.email,
        fullName: `${result.data.firstName} ${result.data.lastName}`,
        isPordalUser: true,
      });
    }
  }

  setPordalConfig(data) {
    const config = {
      ...data,
      expires_at: new Date().getTime() + data.expires_in * 1000,
    };
    localStorage.setItem(PORDAL_TOKEN_CONFIG, JSON.stringify(config));
  }

  handleRefreshToken() {
    const tokenConfigRaw = localStorage.getItem(PORDAL_TOKEN_CONFIG);
    if (!tokenConfigRaw) {
      return;
    }
    const tokenConfig = JSON.parse(tokenConfigRaw);
    const expirationTime = tokenConfig.expires_at - 1000 * 60;
    const currentTime = new Date().getTime();

    if (expirationTime < currentTime) {
      this.logoutProceed();
    } else {
      const timeout = setTimeout(async () => {
        try {
          const result = await this.refreshToken(tokenConfig.refresh_token);
          if (result.data) {
            this.setPordalConfig({ ...result.data, refresh_token: tokenConfig.refresh_token });
            this.handleRefreshToken();
          }
        } catch (error) {
          if (
            error?.response &&
            error?.response?.status === 400 &&
            error?.response?.data?.title === 'invalid_grant'
          ) {
            this.logoutProceed();
          }
        } finally {
          clearTimeout(timeout);
        }
      }, expirationTime - currentTime);
    }
  }

  async refreshToken(refreshToken) {
    return Axios.post(`${environment.pordalApi}/oauth`, {
      grant_type: 'refresh_token',
      client_id: environment.pordalAppClient,
      refresh_token: refreshToken,
    });
  }

  async authorizeAdmin(token) {
    const data = `token=${token}`;

    try {
      const { data: response } = await Axios.post(`${environment.ownerApi}/login_as_admin`, data);

      if (!response.accepted) {
        throw new Error('An error occured');
      }
      const userData = {
        ...response.owner_data,
        userId: response.owner_data.id || response.owner_data.pordal_id,
        name: response.owner_data.email,
        fullName: response.owner_data.full_name,
      };

      this.setAdminToken(token);
      this.setUser(userData);

      return userData;
    } catch (error) {
      authService.logoutProceed();

      throw new Error(error);
    }
  }

  setAdminToken(token) {
    localStorage.setItem(ADMIN_TOKEN_NAME, token);
  }

  setBearerToken(tokenData) {
    localStorage.setItem(ADMIN_ACCESS_TOKEN_NAME, tokenData);
  }

  parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        // eslint-disable-next-line prefer-template
        .map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  getUserData() {
    const token = this.getUserToken();
    this.setUser(this.parseJwt(token));
  }

  changePassword(email, oldPassword, newPassword) {
    const postData = this.prepareChangePasswordData(email, oldPassword, newPassword);

    return new Promise((resolve, reject) =>
      this.changePasswordPost(postData)
        .then((response) => {
          this.setUserToken(response.data.token);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
    );
  }

  changePordalPassword(email, oldPassword, newPassword, ownerId) {
    const postData = this.prepareChangePordalPasswordData(email, oldPassword, newPassword);

    return new Promise((resolve, reject) =>
      this.changePordalPasswordPost(postData, ownerId)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const validationMessages = error?.response?.data?.validationMessages;
          const oldPasswordError = validationMessages?.oldPassword;

          if (oldPasswordError) {
            reject(oldPasswordError);
          } else {
            reject(error?.message || 'An unknown error occurred');
          }
        })
    );
  }

  getAdminTokenForPassword() {
    const data = {
      username: environment.pordalAdminUser,
      password: environment.pordalAdminPassword,
    };

    return new Promise((resolve, reject) =>
      Axios.post(`${environment.pordalAdminApi}/login`, data)
        .then((response) => resolve(response.data))
        .catch((error) => {
          reject(error);
        })
    );
  }

  getPordalUser(user, token) {
    return new Promise((resolve, reject) =>
      Axios.get(
        `${environment.pordalAdminApi}/owner?filter=[{ "field": "email", "type": "like", "value": "${user?.name}"}]`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then((response) => resolve(response.data?._embedded?.owner[0]))
        .catch((error) => {
          reject(error);
        })
    );
  }

  async changePasswordFromAdmin(password, user) {
    const data = { passwordConfirm: password, password };
    const tokenData = await this.getAdminTokenForPassword();
    const pordalUser = await this.getPordalUser(user, tokenData?.access_token);

    if (!pordalUser) return true;

    const headers = {
      headers: {
        Authorization: `Bearer ${tokenData?.access_token}`,
        'Content-Type': 'application/json',
        'x-tenant-code': environment.pordalAppClient,
        Accept: '*/*',
      },
    };

    const userId = pordalUser?.id ? pordalUser?.id : user?.userId;

    return Axios.put(
      `${environment.pordalAdminApi}/owner-password-change/${userId}`,
      data,
      headers
    );
  }

  resetPassword(email) {
    return new Promise((resolve, reject) =>
      this.resetPasswordPost(email)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
    );
  }

  setNewPassword(newPassword, passwordConfirm, token) {
    const decodedToken = decodeURIComponent(token);
    const data = {
      token: decodedToken,
      password: newPassword,
      passwordConfirm,
    };

    return new Promise((resolve, reject) =>
      this.setNewPasswordPost(data, decodedToken)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
    );
  }

  isLoggedIn() {
    return (
      (this.getUserToken() != null && this.currentUser != null) ||
      (this.getAdminToken() !== null && this.currentUser != null)
    );
  }

  logout() {
    const postData = {
      apiKey: environment.apiKey,
      accountId: environment.accountId,
    };
    const userStatus = localStorage.getItem(USER_STATUS);

    return new Promise((resolve, reject) => {
      if (this.getUserToken() && (userStatus === 'both' || userStatus === 'guesty')) {
        return this.logoutPost(postData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            this.logoutProceed();
          });
      }

      this.logoutProceed();
      resolve();
    });
  }

  async getPordalPropertyToken(data) {
    try {
      const result = await Axios.post(`${environment.pordalApi}/oauth`, data);

      return result;
    } catch (error) {
      return {
        success: false,
        message: error.response?.data?.detail || error,
      };
    }
  }

  logoutProceed() {
    localStorage.clear();
  }

  getUser() {
    const jsonUser = localStorage.getItem(USER_SESSION);
    if (jsonUser) {
      return JSON.parse(jsonUser);
    }

    return null;
  }

  setUser(user) {
    localStorage.setItem(USER_SESSION, JSON.stringify(user));
    this.currentUser = user;
  }

  getUserToken() {
    return localStorage.getItem(USER_TOKEN_NAME);
  }

  getAdminToken() {
    return localStorage.getItem(ADMIN_TOKEN_NAME);
  }

  setUserToken(token) {
    localStorage.setItem(USER_TOKEN_NAME, token);
  }

  getPordalConfig() {
    return JSON.parse(localStorage.getItem(PORDAL_TOKEN_CONFIG));
  }

  prepareLoginData(email, password) {
    return {
      accountId: environment.accountId,
      username: email,
      password,
      apiKey: environment.apiKey,
    };
  }

  preparePordalLoginData(email, password) {
    return {
      username: email,
      password,
      grant_type: 'password',
      client_id: environment.pordalAppClient,
    };
  }

  prepareChangePasswordData(email, oldPassword, newPassword) {
    return {
      accountId: environment.accountId,
      username: email,
      password: oldPassword,
      newPassword,
      apiKey: environment.apiKey,
    };
  }

  prepareChangePordalPasswordData(email, oldPassword, newPassword) {
    return {
      oldPassword,
      password: newPassword,
      passwordConfirm: newPassword,
    };
  }

  getBasicAuth(email, password) {
    return `Basic ${Buffer.from(`${email}:${password}`).toString('base64')}`;
  }

  authenticatePost(data) {
    return Axios.post(`${environment.ownerApi}/login`, data);
  }

  resetPasswordPost(email) {
    return Axios.get(`${environment.pordalApi}/password/owner-forgot?email=${email}`);
  }

  checkNewPasswordTokenValid(token) {
    return Axios.get(`${environment.pordalApi}/password/check-reset-token?account=2&token=${token}`)
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
  }

  async setNewPasswordPost(sendData, token) {
    const {
      data: { error, success },
    } = await this.checkNewPasswordTokenValid(token);

    if (success && !error) {
      return Axios.post(`${environment.pordalApi}/password/owner-reset`, sendData);
    }

    return error;
  }

  changePasswordPost(data) {
    return Axios.post(`${environment.guestyApi}/authenticate/password/change`, data);
  }

  changePordalPasswordPost(data, ownerId) {
    return Axios.post(`${environment.pordalApi}/owner-password-change/${ownerId}`, data);
  }

  logoutPost(data) {
    return Axios.post(`${environment.guestyApi}/authenticate/revoke`, data);
  }
}

export const authService = new AuthService();
